import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "flash", "message" ]

  connect() {
    if (this.hasMessageTarget) this.highlight()
  }

  disconnect() {
    clearTimeout(this.timeout)
    this.clear()
  }

  // For rails redirect flash messages
  clear() {
    this.hide()
    if (this.hasMessageTarget) this.messageTarget.remove()
  }

  show() {
    this.flashTarget.classList.remove("hidden")
  }

  hide() {
    this.flashTarget.classList.add("hidden")
  }

  highlight() {
    this.show()
    this.timeout = setTimeout(() => this.hide(), 1500)
  }
}

// closeFlash = () => {
//   this.flashBox.fadeOut(300, () => {
//     this.flashBox.remove();
//   });
// }
